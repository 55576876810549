import React from 'react'

import bg1 from '../assets/images/bg-section1.jpg'
import bg2 from '../assets/images/bg-section2.jpg'
import {MailForm} from './mailchamp'
import {IntegrationsCarousel} from './integrations-carousel'
import {Logo} from './logo-type'
import {SignUpButton} from './signup-button'
import {ContactButton} from './contact-button'
import {MenuList} from './menu-list'

import './frame1-home.css'

const Frame1Home = props => {
	const handleContactClick = () => {
		const contactBoxHeight = document.querySelector(
			'.frame1-home-container6'
		).offsetHeight
		window.scrollTo({
			top: document.body.scrollHeight - contactBoxHeight,
			behavior: 'smooth'
		})
	}

	return (
		<div className="frame1-home-container">
			<div className="frame1-home-frame1-home">
				<div className="frame1-home-header">
					<div className="frame1-home-container1">
						<Logo />
						<div className="header-buttons-container">
							<div className="frame1-home-colright">
								<div className="frame1-home-menu">
									<div className="frame1-home-menuitem"></div>
									<div className="frame1-home-menuitem1">
										<span className="frame1-home-text002">
											<a href="https://dashboard.excofy.com/pt/login">
												<span>ENTRAR</span>
											</a>
										</span>
									</div>
								</div>
								<SignUpButton />
							</div>
							<MenuList />
						</div>
					</div>
				</div>
				<div
					className="frame1-home-section"
					style={{backgroundImage: `url(${bg1})`}}
				>
					<div className="frame1-home-container2">
						<div className="frame1-home-frame179">
							<div className="frame1-home-frame11">
								<div className="frame1-home-frame183">
									<div>
										<div className="frame1-home-group81">
											<span className="frame1-home-text010 TitlesH1">
												<span
													className="frame1-home-text011"
													style={{fontWeight: 500}}
												>
													Uma plataforma moderna para
												</span>{' '}
												<span
													style={{color: 'rgb(241, 187, 12)', fontWeight: 500}}
												>
													vendas online
												</span>
											</span>
											<span className="frame1-home-text013 24Regular">
												<span>
													Na Excofy você vende seus produtos digitais e serviços
													com simplicidade e alta performance.
												</span>
											</span>
										</div>
										<div className="section-1-button-containers">
											<SignUpButton />
											<ContactButton onClick={handleContactClick} />
										</div>
									</div>
									<div className="frame1-home-group80">
										<img
											src="/external/Home-Features.svg"
											alt="LandingPage12906"
											className="frame1-home-landing-page1"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="frame1-home-section1">
					<div className="frame1-home-container3">
						<div className="frame1-home-title">
							<div className="frame1-home-divider"></div>
							<span className="frame1-home-text015 24Medium">
								<span>Integrações e Módulos</span>
							</span>
							<div className="frame1-home-divider1"></div>
						</div>
						<IntegrationsCarousel />
					</div>
				</div>
				<div className="frame1-home-section2">
					<div className="frame1-home-container4">
						<div className="frame1-home-oquee">
							<div className="frame1-home-title1">
								<span className="frame1-home-text017">
									<span>— Customização</span>
								</span>
								<span className="frame1-home-text019 TitlesH2">
									<span className="frame1-home-text020">Autonomia</span>
									<span> para você vender online</span>
								</span>
								<span className="frame1-home-text022">
									<p>
										<span className="frame1-home-text023">
											No Excofy, você pode adicionar sua marca e personalizar a
											cor primária, garantindo que todas as ferramentas
											integradas reflitam a
											<span
												dangerouslySetInnerHTML={{
													__html: ' '
												}}
											/>
										</span>
										<span className="frame1-home-text024">
											identidade da sua empresa
										</span>
										<span className="frame1-home-text025">.</span>
									</p>
									<br />
									<p>
										<span className="frame1-home-text027">
											Com
											<span
												dangerouslySetInnerHTML={{
													__html: ' '
												}}
											/>
										</span>
										<span className="frame1-home-text028">estilo</span>
										<span>
											{' '}
											e consistência, você proporciona uma experiência de compra
											excepcional para seus clientes, sem a necessidade de
											códigos.
										</span>
									</p>
									<br />
								</span>
							</div>
							<div className="frame1-home-mockup-desktop-container">
								<div className="frame1-home-mockup-desktop-container-img">
									<img
										src="/external/rectangle3i2906-2hno-400h.png"
										alt="Rectangle3I2906"
										className="frame1-home-mockup-desktop"
									/>
								</div>
								<div className="frame1-home-mockup-desktop-image" />
							</div>
						</div>
						<div
							className="section-2-2"
							style={{
								padding: 32,
								width: '100%',
								display: 'flex',
								gap: '32px',
								flexDirection: 'column'
							}}
						>
							<p className="frame1-home-text022">
								<span className="frame1-home-text027">
									Para quem é o Excofy?
								</span>
							</p>
							<div className="frame1-home-ul">
								<div className="frame1-home-li">
									<div className="frame1-home-row">
										<div className="frame1-home-col">
											<span className="frame1-home-text030">
												<span>01</span>
											</span>
										</div>
										<div className="frame1-home-col01">
											<span className="frame1-home-text032 18Medium">
												<span>Produtores de infoproduto</span>
											</span>
										</div>
									</div>
								</div>
								<div className="frame1-home-li frame1-home-li01">
									<div className="frame1-home-row1">
										<div className="frame1-home-col02">
											<span className="frame1-home-text034">
												<span>02</span>
											</span>
										</div>
										<div className="frame1-home-col03">
											<span className="frame1-home-text036 18Medium">
												<span>Prestadores de serviços</span>
											</span>
										</div>
									</div>
								</div>
								<div className="frame1-home-li frame1-home-li02">
									<div className="frame1-home-row2">
										<div className="frame1-home-col04">
											<span className="frame1-home-text038">
												<span>03</span>
											</span>
										</div>
										<div className="frame1-home-col05">
											<span className="frame1-home-text040 18Medium">
												<span>Criadores de conteúdo</span>
											</span>
										</div>
									</div>
								</div>
								<div className="frame1-home-li frame1-home-li03">
									<div className="frame1-home-row3">
										<div className="frame1-home-col06">
											<span className="frame1-home-text042">
												<span>04</span>
											</span>
										</div>
										<div className="frame1-home-col07">
											<span className="frame1-home-text044 18Medium">
												<span>Empresas de educação</span>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="frame1-home-section3"
					style={{backgroundImage: `url(${bg2})`}}
				>
					<div className="frame1-home-container5">
						<div className="frame1-home-title2">
							<div className="frame1-home-frame15">
								<span className="frame1-home-text046">
									<span>— Funcionalidades</span>
								</span>
								<span className="frame1-home-text048 TitlesH2">
									<span className="frame1-home-text049">
										Todos os recursos para você operar com
										<span
											dangerouslySetInnerHTML={{
												__html: ' '
											}}
										/>
									</span>
									<span style={{color: 'rgb(241, 187, 12)'}}>
										flexibilidade
									</span>
								</span>
							</div>
							<span className="frame1-home-text051 18Regular">
								<span>
									Uma ferramenta performática, flexível em integrações e, acima
									de tudo, com excelente usabilidade.
								</span>
							</span>
						</div>
						<div className="frame1-home-ul1">
							<div className="frame1-home-li2 frame1-home-li05">
								<div className="frame1-home-frame13">
									<div className="frame1-home-frame16">
										<img
											src="/external/cocolineticki541-ai4a.svg"
											alt="COCOLineTickI541"
											className="frame1-home-coco-line-tick"
										/>
									</div>
									<div className="frame1-home-frame14">
										<span className="frame1-home-text053">
											<span>Plataforma SaaS whitelabel</span>
										</span>
										<span className="frame1-home-text055 14Medium">
											<span>
												Com o Excofy você fica em primeiro plano. Configure a
												interface com a sua própria marca e suas cores e ofereça
												uma jornada de compra exclusiva para seus clientes.
											</span>
										</span>
									</div>
								</div>
							</div>
							<div className="frame1-home-li2 frame1-home-li06">
								<div className="frame1-home-frame131">
									<div className="frame1-home-frame161">
										<img
											src="/external/cocolineticki541-cnnq.svg"
											alt="COCOLineTickI541"
											className="frame1-home-coco-line-tick1"
										/>
									</div>
									<div className="frame1-home-frame141">
										<span className="frame1-home-text057">
											<span>Facilidade de uso</span>
										</span>
										<span className="frame1-home-text059 14Medium">
											<span>
												Removemos as barreiras de configurações complexas,
												setups e integrações para permitir que você possa
												concentrar seus esforços nas vendas e melhorias dos seus
												produtos.
											</span>
										</span>
									</div>
								</div>
							</div>
							<div className="frame1-home-li2 frame1-home-li08">
								<div className="frame1-home-frame132">
									<div className="frame1-home-frame162">
										<img
											src="/external/cocolineticki541-0xyl.svg"
											alt="COCOLineTickI541"
											className="frame1-home-coco-line-tick2"
										/>
									</div>
									<div className="frame1-home-frame142">
										<span className="frame1-home-text061">
											<span>Página de vendas</span>
										</span>
										<span className="frame1-home-text063 14Medium">
											<span>
												Crie a sua própria página de vendas em alguns minutos.
												Adicione todos os detalhes sobre o seu produto digital
												ou serviço, e comece a vender o quanto antes!
											</span>
										</span>
									</div>
								</div>
							</div>
							<div className="frame1-home-li2 frame1-home-li09">
								<div className="frame1-home-frame133">
									<div className="frame1-home-frame163">
										<img
											src="/external/cocolineticki541-7nma.svg"
											alt="COCOLineTickI541"
											className="frame1-home-coco-line-tick3"
										/>
									</div>
									<div className="frame1-home-frame143">
										<span className="frame1-home-text065">
											<span>Checkout</span>
										</span>
										<span className="frame1-home-text067 14Medium">
											<span>
												Processo de compra intuitivo, rápido e integrado com
												diferentes meios de pagamento (Cartão de crédito, Pix e
												Boleto). Integração nativa com ferramentas de upsell.
											</span>
										</span>
									</div>
								</div>
							</div>
							<div className="frame1-home-li2 frame1-home-li11">
								<div className="frame1-home-frame134">
									<div className="frame1-home-frame164">
										<img
											src="/external/cocolineticki541-ei9r.svg"
											alt="COCOLineTickI541"
											className="frame1-home-coco-line-tick4"
										/>
									</div>
									<div className="frame1-home-frame144">
										<span className="frame1-home-text069">
											<span>Dashboard de análise de dados</span>
										</span>
										<span className="frame1-home-text071 14Medium">
											<span>
												Veja em tempo real como está sua performance de vendas e
												acompanhe a sua evolução mês a mês.
												<span
													dangerouslySetInnerHTML={{
														__html: ' '
													}}
												/>
											</span>
										</span>
									</div>
								</div>
							</div>
							<div className="frame1-home-li2 frame1-home-li12">
								<div className="frame1-home-frame135">
									<div className="frame1-home-frame165">
										<img
											src="/external/cocolineticki541-0uyd.svg"
											alt="COCOLineTickI541"
											className="frame1-home-coco-line-tick5"
										/>
									</div>
									<div className="frame1-home-frame145">
										<span className="frame1-home-text073">
											<span>Gestão de clientes</span>
										</span>
										<span className="frame1-home-text075 14Medium">
											<span>
												Acompanhe todos os pedidos de seus clientes, e gerencie
												seus contatos em um único local!
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
						<SignUpButton />
					</div>
				</div>
				<div className="frame1-home-section4">
					<div className="frame1-home-containerfluid">
						<div className="frame1-home-img">
							<div className="frame1-home-frame166">
								<img
									src="/external/image315420-1j3f-900h.png"
									alt="image315420"
									className="frame1-home-image31"
								/>
							</div>
						</div>
						<div className="frame1-home-col08-container">
							<div className="frame1-home-col08">
								<div className="frame1-home-frame80">
									<Logo />
									<span className="frame1-home-text081 TitlesH2">
										<span className="frame1-home-text082">
											Em qualquer lugar
										</span>
										<br />
										<span className="frame1-home-text083">
											A melhor experiência
										</span>
									</span>
								</div>
								<span className="frame1-home-text084 18Regular">
									<span>
										Utilizamos computação de borda (Edge Computing) para
										garantir que você esteja sempre mais próximo de seus
										clientes, em qualquer lugar do mundo. Mais performance para
										nós significa melhores resultados.
										<br />
										<br />
										Nossa plataforma é totalmente responsiva, proporcionando a
										melhor experiência tanto em computadores quanto em
										dispositivos móveis.
									</span>
									<br></br>
									<span></span>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="frame1-home-sectionjoinus">
					<div className="frame1-home-container6">
						<div className="frame1-home-col09">
							<div className="frame1-home-title3">
								<div className="frame1-home-frame12">
									<span className="frame1-home-text088 TitlesH2">
										<span className="frame1-home-text089">Vamos começar?</span>
										<br></br>
										<span className="frame1-home-text091">
											Entre em contato e começe a vender
										</span>
									</span>
									<span className="frame1-home-text093 18Regular">
										<span>Entraremos em contato em até 24 horas</span>
									</span>
								</div>
							</div>
							<MailForm />
						</div>
						<div className="frame1-home-col12">
							<div className="frame1-home-row5">
								<div>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '12px'
										}}
									>
										<a
											href="https://www.facebook.com/excofy"
											target="_blank"
											rel="noreferrer"
										>
											<svg
												stroke="currentColor"
												fill="currentColor"
												strokeWidth="0"
												viewBox="0 0 448 512"
												height="1em"
												width="1em"
												xmlns="http://www.w3.org/2000/svg"
												style={{
													fontSize: '32px',
													opacity: 0.5,
													color: '#fff'
												}}
											>
												<path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
											</svg>
										</a>
										<a
											href="https://www.instagram.com/excofy.platform"
											target="_blank"
											rel="noreferrer"
										>
											<svg
												stroke="currentColor"
												fill="currentColor"
												strokeWidth="0"
												viewBox="0 0 448 512"
												height="1em"
												width="1em"
												xmlns="http://www.w3.org/2000/svg"
												style={{
													fontSize: '32px',
													opacity: 0.5,
													color: '#fff'
												}}
											>
												<path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"></path>
											</svg>
										</a>
										<a
											href="https://www.youtube.com/@excofy"
											target="_blank"
											rel="noreferrer"
										>
											<svg
												stroke="currentColor"
												fill="currentColor"
												strokeWidth="0"
												viewBox="0 0 448 512"
												height="1em"
												width="1em"
												xmlns="http://www.w3.org/2000/svg"
												style={{
													fontSize: '32px',
													opacity: 0.5,
													color: '#fff'
												}}
											>
												<path d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z"></path>
											</svg>
										</a>
									</div>
								</div>
							</div>
							<div className="frame1-home-row6">
								<a href="mailto:contato@excofy.com">
									<span className="frame1-home-text105">
										<span>contato@excofy.com</span>
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Frame1Home
