export const Logo = () => {
	return (
		<div
			className="logo"
			style={{
				display: 'flex',
				alignItems: 'center'
			}}
		>
			<img src="/logo-excofy.svg" width={60} height={60} alt="Excofy Logo" />
			<h1
				style={{
					marginLeft: 8,
					marginTop: 4
				}}
			>
				Excofy
			</h1>
		</div>
	)
}

export const LogoType = () => {
	return (
		<div className="logo-type" style={{display: 'none'}}>
			<img src="/logo-excofy.svg" width={60} height={60} alt="Excofy Logo" />
		</div>
	)
}
